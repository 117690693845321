import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Body from "../../components/Body/Body";
import NetWorthProgress from "../../components/Home/NetWorthProgress";
import Statistics from "../../components/Home/Statistics";
import PieChart from "../../components/Home/PieChart";
import useAssetsStore from "../../store/AssetsStore";
import useCryptoStore from "../../store/CryptoStore";
import useStocksStore from "../../store/StocksStore";
import useTransactionsStore from "../../store/TransactionsStore";
import useAssetsLogic from "../../hooks/AssetsLogic";
import useCryptoLogic from "../../hooks/CryptoLogic";
import useStocksLogic from "../../hooks/StocksLogic";
import useTransactionsLogic from "../../hooks/TransactionsLogic";
import useCurrentUserLogic from "../../hooks/CurrentUserLogic";
import { useNotification } from "../../components/Notification/NotificationProvider";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Home = () => {
  const [page] = useState(localStorage.getItem("lastPage"));
  const [userLastLogInDevice] = useState(
    localStorage.getItem("userLastLogInDevice")
  );

  const { addNotification } = useNotification();

  const [loading, setLoading] = useState(false);

  const { fetchUserLastLogIn, changeUserLastLogIn } = useCurrentUserLogic();

  const { transactions, resetTransactionsStore } = useTransactionsStore();
  const { setRefreshTransactions } = useTransactionsLogic();

  const { cryptoCurrencies, cryptoTransactions, resetCryptoStore } =
    useCryptoStore();
  const { setRefreshCrypto } = useCryptoLogic();

  const { stocks, stockTransactions, resetStocksStore } = useStocksStore();
  const { setRefreshStocks } = useStocksLogic();

  const { assets, resetAssetsStore } = useAssetsStore();
  const { setRefreshAssets } = useAssetsLogic();

  const [overviewData, setOverviewData] = useState({
    balance: null,
    cryptoWorth: null,
    stocksWorth: null,
    assetWorth: null,
    total: null,
  });

  const [currentChart, setCurrentChart] = useState(0); // 0 for balance, 1 for expenses, 2 for incomes

  const navigate = useNavigate();

  const calculateGoal = (netWorth) => {
    const initialGoal = 625;
    const multiplier = 2;

    let goal = initialGoal;

    while (netWorth >= goal) {
      goal *= multiplier;
    }

    return goal;
  };

  const totalNetWorth =
    overviewData.balance +
    overviewData.assetWorth +
    overviewData.stocksWorth +
    overviewData.cryptoWorth;

  const goal = calculateGoal(totalNetWorth);

  const percentage = Math.min((totalNetWorth / goal) * 100, 100);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const responseGet = await fetchUserLastLogIn();

        if (!userLastLogInDevice && !page) {
          await setDeviceId(); // on first load (login) just set the deviceId
        } else if (responseGet.deviceId !== userLastLogInDevice) {
          resetTransactionsStore();
          resetCryptoStore();
          resetStocksStore();
          resetAssetsStore();

          await setDeviceId();
        }

        setLoading(false);
      } catch (error) {
        const errorMessage = `Failed to fetch last log in data: ${error?.message}`;
        console.error(errorMessage);
        if (error?.message !== undefined) {
          addNotification(errorMessage);
        }
      }
    };

    const setDeviceId = async () => {
      try {
        const resultFetch = await changeUserLastLogIn();
        localStorage.setItem("userLastLogInDevice", resultFetch.data.deviceId);
      } catch (error) {
        console.error("Error updating last log in data:", error);
      }
    };

    fetchData(); // comment this line to disable refresh on device change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const possiblePages = ["transactions", "cryptos", "stocks", "assets"];

    if (!loading) {
      if (page && possiblePages.includes(page)) {
        navigate(`/${page}`);
      } else {
        navigate("/");
        localStorage.setItem("lastPage", "");
      }
    }
  }, [loading, page, navigate]);

  useEffect(() => {
    setRefreshTransactions(true);
    setRefreshAssets(true);
    setRefreshCrypto(true);
    setRefreshStocks(true);
  }, [
    setRefreshTransactions,
    setRefreshAssets,
    setRefreshCrypto,
    setRefreshStocks,
  ]);

  useEffect(() => {
    if (
      transactions?.length ||
      cryptoTransactions?.length ||
      stockTransactions?.length ||
      assets?.length
    ) {
      const balance = transactions
        .filter(
          (transaction) => new Date(transaction.transactionDate) <= new Date()
        )
        .reduce(
          (accumulator, transaction) => accumulator + transaction.amount,
          0
        );

      const cryptoWorth = cryptoTransactions.reduce((total, transaction) => {
        const currency = cryptoCurrencies.find(
          (currency) => currency.id === transaction.cryptoCurrencyId
        );
        return (
          total + transaction.cryptoAmount * (currency ? currency.price : 0)
        );
      }, 0);

      const stocksWorth = stockTransactions.reduce((total, transaction) => {
        const currency = stocks.find(
          (currency) => currency.id === transaction.stockId
        );
        return total + transaction.amount * (currency ? currency.price : 0);
      }, 0);

      const assetWorth = assets.reduce(
        (total, asset) => total + asset.currentWorth,
        0
      );

      const total = balance + cryptoWorth + stocksWorth + assetWorth;

      setOverviewData({
        balance,
        cryptoWorth,
        stocksWorth,
        assetWorth,
        total,
      });
    }
  }, [
    transactions,
    assets,
    cryptoCurrencies,
    cryptoTransactions,
    stocks,
    stockTransactions,
  ]);

  // Calculate expense categories (negative amounts)
  const categoryExpenses = transactions.reduce((acc, transaction) => {
    const { transactionCategory, amount, transactionDate } = transaction;
    if (
      transactionCategory &&
      amount < 0 &&
      new Date(transactionDate).getFullYear() === new Date().getFullYear()
    ) {
      if (!acc[transactionCategory]) acc[transactionCategory] = 0;
      acc[transactionCategory] += amount;
    }
    return acc;
  }, {});

  // Calculate income categories (positive amounts)
  const categoryIncome = transactions.reduce((acc, transaction) => {
    const { transactionCategory, amount, transactionDate } = transaction;
    if (
      transactionCategory &&
      amount > 0 &&
      new Date(transactionDate).getFullYear() === new Date().getFullYear()
    ) {
      if (!acc[transactionCategory]) acc[transactionCategory] = 0;
      acc[transactionCategory] += amount;
    }
    return acc;
  }, {});

  // Sort both income and expenses by amount
  const sortedExpenses = Object.entries(categoryExpenses).sort(
    (a, b) => a[1] - b[1]
  );
  const sortedIncome = Object.entries(categoryIncome).sort(
    (a, b) => b[1] - a[1]
  );

  const topSpendingCategories = sortedExpenses.slice(0, 5);
  const topIncomeCategories = sortedIncome.slice(0, 5);

  const anyDataPresent = () => {
    if (
      transactions.length > 0 ||
      assets.length > 0 ||
      cryptoTransactions.length > 0 ||
      stockTransactions.length > 0
    ) {
      return true;
    }

    return false;
  };

  if (page) return null;

  return (
    <>
      <Header />
      <Body>
        <NetWorthProgress
          percentage={percentage}
          total={overviewData.total}
          goal={goal}
        />

        {anyDataPresent() ? (
          <>
            <hr className="mt-5" />

            <Statistics
              currentChart={currentChart}
              topSpendingCategories={topSpendingCategories}
              topIncomeCategories={topIncomeCategories}
              overviewData={overviewData}
            />

            <>
              <PieChart
                currentChart={currentChart}
                setCurrentChart={setCurrentChart}
                overviewData={overviewData}
                categoryExpenses={categoryExpenses}
                categoryIncome={categoryIncome}
              />

              <FaChevronLeft
                size={30}
                className="absolute left-2 top-2/4 cursor-pointer hover:opacity-80 text-black dark:text-gray-300"
                onClick={() =>
                  setCurrentChart((prev) => (prev === 0 ? 2 : prev - 1))
                }
              />

              <FaChevronRight
                size={30}
                className="absolute right-2 top-2/4 cursor-pointer hover:opacity-80 text-black dark:text-gray-300"
                onClick={() =>
                  setCurrentChart((prev) => (prev === 2 ? 0 : prev + 1))
                }
              />
            </>
          </>
        ) : (
          <>
            <hr className="mt-10" />

            <div className="flex justify-center text-center font-bold text-2xl lg:text-3xl mt-10 mx-4 text-black dark:text-gray-300">
              Welcome to Wherepare
              <img
                src="/images/logo512.png"
                alt="Wherepare Logo"
                className="self-center h-7 w-7 ml-1"
              />
            </div>

            <div className="flex justify-center text-center text-lg lg:text-xl mt-4 mx-4 text-black dark:text-gray-300">
              When you start entering data, the statistics will be shown here.
            </div>
          </>
        )}
      </Body>
    </>
  );
};

export default Home;

import { create } from "zustand";

const useTransactionsStore = create((set) => ({
  loading: false,
  editing: false,
  dataChanged: true,
  transactions: [],
  transactionCategories: [],
  selectedCategory: -1,
  transactionAccounts: [],
  selectedAccount: -1,
  transactionTags: [],
  selectedTag: -1,
  selectedTimeFrame: 1,
  repeatIntervals: [],
  repeatingTransactions: [],

  setLoading: (loading) => set({ loading }),

  setEditing: (editing) => set({ editing }),

  setDataChanged: (dataChanged) => set({ dataChanged }),

  setTransactions: (newTransactions) => set({ transactions: newTransactions }),

  setTransactionCategories: (newTransactionCategories) =>
    set({ transactionCategories: newTransactionCategories }),

  setSelectedCategory: (selectedCategory) => set({ selectedCategory }),

  setTransactionAccounts: (newTransactionAccounts) =>
    set({ transactionAccounts: newTransactionAccounts }),

  setSelectedAccount: (selectedAccount) => set({ selectedAccount }),

  setTransactionTags: (newTransactionTags) =>
    set({ transactionTags: newTransactionTags }),

  setSelectedTag: (selectedTag) => set({ selectedTag }),

  setSelectedTimeFrame: (selectedTimeFrame) => set({ selectedTimeFrame }),

  setRepeatIntervals: (newRepeatIntervals) =>
    set({ repeatIntervals: newRepeatIntervals }),

  setRepeatingTransactions: (newRepeatingTransactions) =>
    set({ repeatingTransactions: newRepeatingTransactions }),

  resetTransactionsStore: () => {
    localStorage.removeItem("transactions");
    localStorage.removeItem("repeatingTransactions");
    localStorage.removeItem("transactionCategories");
    localStorage.removeItem("transactionAccounts");
    localStorage.removeItem("transactionTags");
    localStorage.removeItem("repeatIntervals");

    set(() => ({
      loading: false,
      editing: false,
      dataChanged: true,
      transactions: [],
      transactionCategories: [],
      selectedCategory: -1,
      transactionAccounts: [],
      selectedAccount: -1,
      transactionTags: [],
      selectedTag: -1,
      selectedTimeFrame: 1,
      repeatIntervals: [],
      repeatingTransactions: [],
    }));
  },
}));

export default useTransactionsStore;

import React from "react";
import StockTransactionsListItem from "./StockTransactionsListItem";

const StockTransactionsList = ({ ticker, transactions, openModal }) => {
  return (
    <>
      <div className="flex items-center justify-between border border-gray-200 dark:border-gray-700 p-4 rounded-lg mb-2 bg-white dark:bg-gray-800 shadow-sm">
        <div className="flex-1">
          <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-1 text-center">
            <p className="text-sm text-gray-600 dark:text-gray-300">
              <strong>Date</strong>
            </p>

            <p className="text-sm text-gray-600 dark:text-gray-300 hidden lg:block">
              <strong>Description</strong>
            </p>

            <p className="text-sm text-gray-600 dark:text-gray-300">
              <strong>Quantity</strong>
            </p>

            <p className="text-sm text-gray-600 dark:text-gray-300 hidden md:block">
              <strong>Price per Unit</strong>
            </p>

            <p className="text-sm text-gray-600 dark:text-gray-300">
              <strong>Total Price</strong>
            </p>
          </div>
          <hr className="my-1" />
          {transactions.map((transaction) => (
            <StockTransactionsListItem
              transaction={transaction}
              openModal={openModal}
              key={transaction.id}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default StockTransactionsList;

import React, { useState } from "react";
import { formatMoney } from "../../../services/FormatService/FormatText";
import CryptoTransactionsList from "./CryptoTransactionsList";
import { FaCoins } from "react-icons/fa";

const CryptoTransactionsGroup = ({
  currency,
  transactions,
  totalCryptoAmount,
  totalPurchasePrice,
  totalCurrentValue,
  openModal,
}) => {
  const [visibility, setVisibility] = useState(false);

  const percentagePL =
    ((totalCurrentValue - totalPurchasePrice) / totalPurchasePrice) * 100;

  return (
    <>
      {totalCurrentValue > 0.009 && (
        <>
          <div
            key={currency.id}
            className={`mt-8 mx-5 p-4 border rounded-lg shadow-md ${
              percentagePL > 0
                ? "bg-green-50 dark:bg-green-900/40 border-x-0 border-b-0 border-t-4 border-green-600 hover:bg-green-100 dark:hover:bg-green-800/50"
                : "bg-red-50 dark:bg-red-950/40 border-x-0 border-b-0 border-t-4 border-red-500 hover:bg-red-100 dark:hover:bg-red-900/40"
            }`}
          >
            {/* Currency Header with Icon */}
            <div className="flex items-center justify-center">
              <FaCoins className="text-2xl mr-2 text-yellow-500" />
              <h2 className="text-xl font-bold text-black dark:text-gray-300">
                {currency.name} ({currency.ticker})
              </h2>
            </div>

            <div className="flex items-center mb-2 justify-center">
              <h3 className="text-sm md:text-base font-light text-gray-500 dark:text-gray-400">
                {formatMoney(currency.price, 2)}
                {" € "}
                {currency.globalUpdate && (
                  <>({new Date(currency.updateDate).toLocaleString()})</>
                )}
              </h3>
            </div>

            {/* Summary */}
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-1 text-center lg:text-lg md:text-base text-sm">
              <h3 className="text-black dark:text-gray-300">
                Holdings:{" "}
                <span className="font-bold">
                  {totalCryptoAmount.toFixed(8)}
                </span>
              </h3>

              <h3 className="text-black dark:text-gray-300 hidden sm:block">
                Total Invested:{" "}
                <span className="font-bold">
                  {formatMoney(totalPurchasePrice, 2)} €
                </span>
              </h3>

              <h3 className="text-black dark:text-gray-300 hidden sm:block">
                Investment Growth:{" "}
                <span
                  className={`font-bold ${
                    percentagePL < 0 ? "text-red-500" : "text-green-500"
                  }`}
                >
                  {percentagePL >= 0 && "+"}
                  {formatMoney(percentagePL, 2)}%
                </span>
              </h3>

              <h3 className="text-black dark:text-gray-300">
                Current Value:{" "}
                <span className="font-bold">
                  {formatMoney(totalCurrentValue, 2)}
                  {" € "}
                  <span
                    className={`sm:hidden font-bold ${
                      totalCurrentValue - totalPurchasePrice < 0
                        ? "text-red-500"
                        : "text-green-500"
                    }`}
                  >
                    ({totalCurrentValue - totalPurchasePrice >= 0 && "+"}
                    {formatMoney(percentagePL, 2)}%)
                  </span>
                </span>
              </h3>
            </div>

            {/* List all transactions for this currency */}
            <>
              <h4
                className={`lg:text-base text-sm font-semibold mt-2 ${
                  visibility ? "mb-2" : "mb-1"
                } text-center text-black dark:text-gray-300 hover:opacity-80 cursor-pointer select-none`}
                onClick={() => setVisibility(!visibility)}
              >
                {visibility ? "Hide transactions" : "Show transactions"}
              </h4>
              {visibility && (
                <CryptoTransactionsList
                  ticker={currency.ticker}
                  transactions={transactions}
                  openModal={openModal}
                />
              )}
            </>
          </div>
        </>
      )}
    </>
  );
};

export default CryptoTransactionsGroup;

import { useState, useEffect } from "react";
import useStocksStore from "../store/StocksStore";
import useStocksData from "../services/DataService/StocksData";
import { useNotification } from "../components/Notification/NotificationProvider";

const useStocksLogic = () => {
  const { addNotification } = useNotification();

  const {
    setLoading,
    setEditing,
    dataChanged,
    setDataChanged,
    setStocks,
    setStockTransactions,
    resetStocksStore,
  } = useStocksStore();

  const {
    getStocks,
    getStockTransactions,
    createStock,
    createStockTransaction,
    updateStock,
    updateStockTransaction,
    updateStocksPrices,
    deleteStock,
    deleteStockTransaction,
  } = useStocksData();

  const [refreshStocks, setRefreshStocks] = useState(false);

  useEffect(() => {
    if (dataChanged) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChanged, refreshStocks]);

  const loadData = async () => {
    try {
      setLoading(true);

      let stocksData = await loadStocks();
      setStocks(stocksData);

      let stockTransactionsData = await loadStockTransactions(stocksData);
      setStockTransactions(stockTransactionsData);
    } catch (error) {
      const errorMessage = `Failed to fetch stocks data: ${error?.message}`;
      console.error(errorMessage);
      if (error?.message !== undefined) {
        addNotification(errorMessage);
      }
    } finally {
      setDataChanged(false);
      setLoading(false);
    }
  };

  // LOAD

  const loadStocks = async (stockTransactionsData) => {
    let stocksData = JSON.parse(localStorage.getItem("stocks"));

    if (!stocksData) {
      stocksData = await getStocks(stockTransactionsData);
      if (stocksData) {
        localStorage.setItem("stocks", JSON.stringify(stocksData));
      }
    }

    return stocksData;
  };

  const loadStockTransactions = async (stocksData) => {
    let stockTransactionsData = JSON.parse(
      localStorage.getItem("stockTransactions")
    );

    if (stockTransactionsData) {
      stockTransactionsData.sort(
        (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
      );
    } else {
      stockTransactionsData = await getStockTransactions(stocksData);

      if (stockTransactionsData) {
        localStorage.setItem(
          "stockTransactions",
          JSON.stringify(stockTransactionsData)
        );
      }
    }

    return stockTransactionsData;
  };

  // ADD

  const addStock = async (item) => {
    setEditing(true);

    try {
      const result = await createStock(item);

      localStorage.removeItem("stocks");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      if (error?.message) {
        console.error(error.message);
        addNotification(error.message);
      }
    }

    setEditing(false);
  };

  const addStockTransaction = async (item) => {
    setEditing(true);

    try {
      const result = await createStockTransaction(item);

      if (result?.status !== 200) {
        return result;
      } else {
        // Add necessary fields
        const newTransaction = result.data;

        // Update local storage
        const currentTransactions =
          JSON.parse(localStorage.getItem("stockTransactions")) || [];

        localStorage.setItem(
          "stockTransactions",
          JSON.stringify([...currentTransactions, newTransaction])
        );

        // Refresh view
        setDataChanged(true);
      }
    } catch (error) {
      if (error?.message) {
        console.error(error.message);
        addNotification(error.message);
      }
    }

    setEditing(false);
  };

  // EDIT

  const editStock = async (item) => {
    setEditing(true);

    try {
      const result = await updateStock(item);

      localStorage.removeItem("stocks");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      if (error?.message) {
        console.error(error.message);
        addNotification(error.message);
      }
    }

    setEditing(false);
  };

  const editStockTransaction = async (item) => {
    setEditing(true);

    try {
      const result = await updateStockTransaction(item);

      if (result?.status !== 200) {
        return result;
      } else {
        // Add necessary fields
        const editedTransaction = result.data;

        // Update local storage
        let currentTransactions =
          JSON.parse(localStorage.getItem("stockTransactions")) || [];

        currentTransactions = currentTransactions.map((tx) =>
          tx.id === editedTransaction.id ? editedTransaction : tx
        );

        localStorage.setItem(
          "stockTransactions",
          JSON.stringify(currentTransactions)
        );

        // Refresh view
        setDataChanged(true);
      }
    } catch (error) {
      if (error?.message) {
        console.error(error.message);
        addNotification(error.message);
      }
    }

    setEditing(false);
  };

  // REFRESH

  const refreshStockPrices = async () => {
    setEditing(true);

    try {
      const result = await updateStocksPrices();

      if (result.data) {
        localStorage.setItem("stocks", JSON.stringify(result.data));
        setStocks(result.data);
        setDataChanged(true);
      } else {
        return result;
      }
    } catch (error) {
      if (error?.message) {
        console.error(error.message);
        addNotification(error.message);
      }
    }

    setEditing(false);
  };

  // REMOVE

  const removeStock = async (item) => {
    setEditing(true);

    try {
      const result = await deleteStock(item);

      localStorage.removeItem("stocks");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      if (error?.message) {
        console.error(error.message);
        addNotification(error.message);
      }
    }

    setEditing(false);
  };

  const removeStockTransaction = async (item) => {
    setEditing(true);

    try {
      const result = await deleteStockTransaction(item);

      if (result?.status !== 200) {
        return result;
      } else {
        // Update local storage
        let currentTransactions =
          JSON.parse(localStorage.getItem("stockTransactions")) || [];

        currentTransactions = currentTransactions.filter(
          (tx) => tx.id !== item.id
        );

        localStorage.setItem(
          "stockTransactions",
          JSON.stringify(currentTransactions)
        );

        // Reset view
        setDataChanged(true);
      }
    } catch (error) {
      if (error?.message) {
        console.error(error.message);
        addNotification(error.message);
      }
    }

    setEditing(false);
  };

  // CLEAR

  const clearStocksCache = () => {
    resetStocksStore();

    refreshStockPrices();

    setDataChanged(true);
  };

  return {
    addStock,
    addStockTransaction,
    editStock,
    editStockTransaction,
    refreshStockPrices,
    removeStock,
    removeStockTransaction,
    clearStocksCache,
    setRefreshStocks,
  };
};

export default useStocksLogic;

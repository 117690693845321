import React from "react";
import Modal from "../../Modal/Modal";
import useCurrentUserStore from "../../../store/CurrentUserStore";
import StocksSettingsStocks from "./StocksSettingsStocks";

const StocksSettings = ({ onClose }) => {
  const { currentUser } = useCurrentUserStore();

  return (
    <Modal onClose={onClose} title={"Stocks Settings"} showFootnote={true}>
      <h2 className="text-xl font-bold mb-4 text-black dark:text-gray-300 text-center cursor-pointer">
        Stocks
      </h2>

      <StocksSettingsStocks currentUser={currentUser} />
    </Modal>
  );
};

export default StocksSettings;

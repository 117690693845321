import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { formatMoney } from "../../services/FormatService/FormatText";
import { FaSpinner } from "react-icons/fa";

ChartJS.register(Title, Tooltip, Legend, ArcElement);

const colors = [
  "#3b82f6", // Blue
  "#10b981", // Green
  "#f43f5e", // Raspberry
  "#facc15", // Amber
  "#d946ef", // Pink
  "#fb923c", // Bright Orange
  "#fca5a1", // Salmon
  "#f97316", // Bright Orange
  "#6366f1", // Indigo
  "#8b5cf6", // Purple
  "#ef4444", // Red
  "#e85d04", // Burnt Orange
  "#22d3ee", // Light Blue
  "#4ade80", // Light Green
  "#eab308", // Gold
  "#38bdf8", // Cyan
  "#14b8a6", // Teal
  "#f59e0b", // Mustard
  "#a855f7", // Violet
  "#6ee7b7", // Mint
  "#a1e8b1", // Pale Green
  "#e7f9ff", // Very Light Blue
  "#8b5f00", // Brown
  "#4f46e5", // Deep Indigo
  "#6eb5ff", // Sky Blue
];

const PieChart = ({
  currentChart,
  setCurrentChart,
  overviewData,
  categoryExpenses,
  categoryIncome,
}) => {
  const sortedExpenses = Object.entries(categoryExpenses)
    .sort((a, b) => a[1] - b[1])
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  const sortedIncome = Object.entries(categoryIncome)
    .sort((a, b) => b[1] - a[1])
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  const categoryExpensesChartData = {
    labels: Object.keys(sortedExpenses),
    datasets: [
      {
        data: Object.values(sortedExpenses),
        backgroundColor: colors,
        borderColor: "#ffffff",
        borderWidth: 2,
      },
    ],
  };

  const categoryIncomeChartData = {
    labels: Object.keys(sortedIncome),
    datasets: [
      {
        data: Object.values(sortedIncome),
        backgroundColor: colors,
        borderColor: "#ffffff",
        borderWidth: 2,
      },
    ],
  };

  const liquidityChartData = {
    labels: ["Balance", "Cryptos", "Stocks", "Assets"],
    datasets: [
      {
        data: [
          overviewData.balance > 0 ? overviewData.balance : 0,
          overviewData.cryptoWorth > 0 ? overviewData.cryptoWorth : 0,
          overviewData.stocksWorth > 0 ? overviewData.stocksWorth : 0,
          overviewData.assetWorth > 0 ? overviewData.assetWorth : 0,
        ],
        backgroundColor: colors,
        borderColor: "#ffffff",
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#6b7280",
          font: {
            size: 16,
          },
          padding: 17.5,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${formatMoney(tooltipItem.raw, 2)} €`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  if (overviewData.total === null) {
    return (
      <div className="relative flex justify-center items-center mt-8">
        <FaSpinner
          size={200}
          className="mt-10 animate-spin text-black dark:text-gray-300"
        />
      </div>
    );
  }

  return (
    <div className="relative flex justify-center items-center my-8">
      <div className="w-[80vw] max-w-lg m-auto h-[50vh]">
        <Pie
          data={
            currentChart === 0
              ? liquidityChartData
              : currentChart === 1
              ? categoryExpensesChartData
              : categoryIncomeChartData
          }
          options={chartOptions}
        />
      </div>
    </div>
  );
};

export default PieChart;

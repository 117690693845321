import { useAuth } from "../../components/Auth/AuthProvider";
import { makeApiCall } from "../ApiService/ApiCall";

const useCurrentUserData = () => {
  const { userId, logOut } = useAuth();

  // GET

  const getUser = async () => {
    const requestUrl = `/Users/GetUser/${userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    return result.data;
  };

  const getUserInfo = async () => {
    const requestUrl = `/Users/GetUserInfo/${userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    return result.data;
  };

  const getUserAddress = async () => {
    const requestUrl = `/Users/GetUserAddress/${userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    return result.data;
  };

  const getUserLastLogIn = async () => {
    const requestUrl = `/Users/GetUserLastLogIn/${userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    return result.data;
  };

  // POST

  const logoutUser = async () => {
    // Clear localStorage, remove userId and set isLoggedIn to false
    logOut();

    // Invalidate tokens and remove all cookies
    const requestUrl = `/Auth/LogoutUser`;
    const result = await makeApiCall("POST", requestUrl, null);

    return result;
  };

  // PUT

  const updateUser = async (item) => {
    const requestUrl = `/Users/UpdateUser/${userId}`;

    const result = await makeApiCall("PUT", requestUrl, {
      Username: item.username,
      Email: item.email,
    });

    return result;
  };

  const updateUserInfo = async (item) => {
    const requestUrl = `/Users/UpdateUserInfo/${userId}`;

    const result = await makeApiCall("PUT", requestUrl, {
      FirstName: item.firstName,
      LastName: item.lastName,
      DateOfBirth: item.dateOfBirth,
    });

    return result;
  };

  const updateUserPassword = async (item) => {
    const requestUrl = `/Users/UpdateUserPassword/${userId}`;

    const result = await makeApiCall("PUT", requestUrl, {
      CurrentPassword: item.currentPassword,
      NewPassword: item.newPassword,
    });

    return result;
  };

  // PATCH

  const updateUserLastLogIn = async (item) => {
    const requestUrl = `/Users/UpdateUserLastLogIn/${userId}`;

    const result = await makeApiCall("PATCH", requestUrl);

    return result;
  };

  return {
    getUser,
    getUserInfo,
    getUserAddress,
    getUserLastLogIn,
    logoutUser,
    updateUser,
    updateUserInfo,
    updateUserPassword,
    updateUserLastLogIn,
  };
};

export default useCurrentUserData;

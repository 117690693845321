import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Body from "../../components/Body/Body";
import TransactionsFilters from "../../components/Transactions/TransactionsFilters/TransactionsFilters";
import TransactionsList from "../../components/Transactions/TransactionsList/TransactionsList";
import TransactionsModal from "../../components/Transactions/TransactionsModal";
import TransactionsSettings from "../../components/Transactions/TransactionsSettings/TransactionsSettings";
import TransactionsRepeating from "../../components/Transactions/TransactionsRepeating/TransactionsRepeating";
import useTransactionsLogic from "../../hooks/TransactionsLogic";
import useTransactionsStore from "../../store/TransactionsStore";
import { FaCog, FaFilter, FaSync } from "react-icons/fa";
import AddButton from "../../components/Body/AddButton";
import { FaFilterCircleXmark, FaRepeat } from "react-icons/fa6";

const Transactions = () => {
  const { loading, editing } = useTransactionsStore();

  const {
    addTransaction,
    addRepeatingTransaction,
    editTransaction,
    editRepeatingTransaction,
    removeTransaction,
    clearTransactionsCache,
  } = useTransactionsLogic();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingTransaction, setEditingTransaction] = useState(null);
  const [isCopy, setIsCopy] = useState(false);

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [isRepeatingOpen, setIsRepeatingOpen] = useState(false);
  const [editRepeating, setEditRepeating] = useState(false);

  const [displayTransactions, setDisplayTransactions] = useState([]);

  const [preferencesChanged, setPreferencesChanged] = useState(false);

  const openModal = (transaction) => {
    setEditingTransaction(transaction);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditingTransaction(null);
    setIsCopy(false);
  };

  const openModalRepeating = (transaction) => {
    setEditingTransaction(transaction);
    setEditRepeating(true);
    setIsModalOpen(true);
  };

  const closeModalRepeating = () => {
    setIsModalOpen(false);
    setEditingTransaction(null);
    setEditRepeating(false);
    setIsCopy(false);
  };

  const handleOpenSettings = () => setIsSettingsOpen(true);
  const handleCloseSettings = () => setIsSettingsOpen(false);

  const handleRepeatingClick = () => setIsRepeatingOpen(!isRepeatingOpen);

  const handleFiltersClick = () => {
    if (isFiltersOpen) {
      setIsFiltersOpen(false);
    } else {
      setIsFiltersOpen(true);
    }
  };

  const handlePreferencesChanged = () => {
    setPreferencesChanged(true);
    //handleCloseSettings(); // close modal after applying settings
  };

  const handleSaveTransaction = async (transaction) => {
    let result = null;

    if (transaction.id && editRepeating) {
      result = await editRepeatingTransaction(transaction);
    } else if (transaction.id) {
      result = await editTransaction(transaction);
    } else if (transaction.repeatInterval > 1) {
      result = await addRepeatingTransaction(transaction);
    } else {
      result = await addTransaction(transaction);
    }

    if (result) {
      alert("An error occured. Please try again.");
    }
  };

  const handleDeleteTransaction = async (transaction) => {
    const result = await removeTransaction(transaction);
    if (result) {
      alert("An error occured. Please try again.");
    }
  };

  return (
    <>
      <Header />

      <Body>
        <div className="absolute top-20 left-4">
          <button
            onClick={handleOpenSettings}
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
          >
            <FaCog size={15} />
          </button>
        </div>

        <div className="absolute top-28 mt-2 left-4">
          <button
            onClick={handleRepeatingClick}
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
          >
            <FaRepeat size={15} />
          </button>
        </div>

        <div className="absolute top-20 right-4">
          <button
            onClick={clearTransactionsCache}
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
            disabled={editing || loading}
          >
            <FaSync size={15} className={`${loading && "animate-spin"}`} />
          </button>
        </div>

        <div className="absolute top-28 mt-2 right-4">
          <button
            onClick={handleFiltersClick}
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
          >
            {isFiltersOpen ? (
              <FaFilterCircleXmark size={15} />
            ) : (
              <FaFilter size={15} />
            )}
          </button>
        </div>

        {isRepeatingOpen ? (
          <>
            <h1
              className={`text-2xl font-bold text-center mt-12 text-black dark:text-gray-300`}
            >
              Repeating transactions
            </h1>

            <TransactionsRepeating
              openModalRepeating={openModalRepeating}
              setIsCopy={setIsCopy}
            />
          </>
        ) : (
          <>
            <TransactionsFilters
              displayTransactions={displayTransactions}
              setDisplayTransactions={setDisplayTransactions}
              isFiltersOpen={isFiltersOpen}
            />

            <TransactionsList
              openModal={openModal}
              displayTransactions={displayTransactions}
              preferencesChanged={preferencesChanged}
              setPreferencesChanged={setPreferencesChanged}
              handleDelete={handleDeleteTransaction}
              setIsCopy={setIsCopy}
            />
          </>
        )}
      </Body>

      {/* Add Transactions button */}
      <AddButton openModal={openModal} isLoading={editing || loading} />

      {isModalOpen && (
        <TransactionsModal
          initialData={editingTransaction}
          isCopy={isCopy}
          isRepeating={editRepeating}
          onSave={handleSaveTransaction}
          onDelete={handleDeleteTransaction}
          onClose={editRepeating ? closeModalRepeating : closeModal}
        />
      )}

      {isSettingsOpen && (
        <TransactionsSettings
          onClose={handleCloseSettings}
          onPreferencesChanged={handlePreferencesChanged}
        />
      )}
    </>
  );
};

export default Transactions;

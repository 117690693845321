import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Body from "../../components/Body/Body";
import AddButton from "../../components/Body/AddButton";
import useStocksLogic from "../../hooks/StocksLogic";
import useStocksStore from "../../store/StocksStore";
import StocksModal from "../../components/Stocks/StocksModal";
import StockTransactions from "../../components/Stocks/StockTransactions/StockTransactions";
import StockTransactionsAlt from "../../components/Stocks/StockTransactionsAlt/StockTransactionsAlt";
import StocksSettings from "../../components/Stocks/StocksSettings/StocksSettings";
import { formatMoney } from "../../services/FormatService/FormatText";
import { FaCog, FaSync } from "react-icons/fa";
import { FaList, FaObjectGroup } from "react-icons/fa6";

const Stocks = () => {
  const { loading, editing, stocks, stockTransactions } = useStocksStore();

  const {
    addStockTransaction,
    editStockTransaction,
    removeStockTransaction,
    clearStocksCache,
  } = useStocksLogic();

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingTransaction, setEditingTransaction] = useState(null);

  const [selectedView, setSelectedView] = useState("groups");

  const handleOpenSettings = () => setIsSettingsOpen(true);
  const handleCloseSettings = () => setIsSettingsOpen(false);

  const openModal = (transaction = null) => {
    setEditingTransaction(transaction);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditingTransaction(null);
  };

  const changeSelectedView = () => {
    selectedView === "groups"
      ? setSelectedView("list")
      : setSelectedView("groups");
  };

  const handleSave = async (transaction) => {
    if (editingTransaction) {
      await editStockTransaction(transaction);
    } else {
      await addStockTransaction(transaction);
    }
    closeModal();
  };

  const totalPurchasePrice = stockTransactions.reduce(
    (total, transaction) => total + transaction.price,
    0
  );

  const totalCurrentWorth = stockTransactions.reduce((total, transaction) => {
    const currency = stocks.find(
      (currency) => currency.id === transaction.stockId
    );

    return total + transaction.amount * (currency ? currency.price : 0);
  }, 0);

  const percentageDifference =
    ((totalCurrentWorth - totalPurchasePrice) / totalPurchasePrice) * 100;

  useEffect(() => {
    if (stockTransactions.length > 0 && totalCurrentWorth <= 0 && !loading) {
      setSelectedView("List");
    }
  }, [stockTransactions, totalCurrentWorth, loading]);

  return (
    <>
      <Header />
      <Body>
        {/* Settings Button */}
        <div className="absolute top-20 left-4">
          <button
            onClick={handleOpenSettings}
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
          >
            <FaCog size={15} />
          </button>
        </div>

        {/* Refresh Button */}
        <div className="absolute top-20 right-4">
          <button
            onClick={clearStocksCache}
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600 disabled:cursor-not-allowed"
            disabled={editing || loading}
          >
            <FaSync size={15} className={`${loading && "animate-spin"}`} />
          </button>
        </div>

        {/* Switch view button */}
        <div className="absolute top-28 mt-2 right-4">
          <button
            onClick={changeSelectedView}
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600 disabled:cursor-not-allowed"
            disabled={totalCurrentWorth <= 0}
          >
            {selectedView === "groups" ? (
              <FaList size={15} />
            ) : (
              <FaObjectGroup size={15} />
            )}
          </button>
        </div>

        <h2 className="text-xl text-center mt-4 text-black dark:text-gray-300">
          {"Total Invested: "}
          <span className="font-bold">
            {formatMoney(totalPurchasePrice, 2)} €
          </span>
        </h2>

        <h2 className="text-xl text-center mt-4 text-black dark:text-gray-300">
          {"Current Net Worth: "}
          <span className="font-bold">
            {formatMoney(totalCurrentWorth, 2)} €
          </span>
        </h2>

        {!isNaN(percentageDifference) && (
          <h2 className="text-xl text-center mt-4 text-black dark:text-gray-300">
            {"Investment Growth: "}
            <span
              className={`font-bold ${
                percentageDifference < 0 ? "text-red-500" : "text-green-500"
              }`}
            >
              {percentageDifference > 0 && "+"}
              {formatMoney(totalCurrentWorth - totalPurchasePrice, 2)} € (
              {percentageDifference > 0 && "+"}
              {formatMoney(percentageDifference, 2)}%)
            </span>
          </h2>
        )}

        {selectedView === "groups" ? (
          <StockTransactions openModal={openModal} />
        ) : (
          <StockTransactionsAlt openModal={openModal} />
        )}
      </Body>

      {/* Add Crypto Transaction Button */}
      <AddButton openModal={openModal} isLoading={editing || loading} />

      {isModalOpen && (
        <StocksModal
          initialData={editingTransaction}
          onSave={handleSave}
          onDelete={removeStockTransaction}
          onClose={closeModal}
        />
      )}

      {isSettingsOpen && <StocksSettings onClose={handleCloseSettings} />}
    </>
  );
};

export default Stocks;

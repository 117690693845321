import React, { useState } from "react";
import TransactionsItem from "../TransactionsList/TransactionsItem";
import useTransactionsStore from "../../../store/TransactionsStore";
import { format } from "date-fns";

const TransactionsRepeatingList = ({
  openModal,
  displayTransactions,
  handleDelete,
  setIsCopy,
}) => {
  const { loading } = useTransactionsStore();

  const [activeMenuItemId, setActiveMenuItemId] = useState(null);

  const closeMenu = () => {
    setActiveMenuItemId(null);
  };

  const openMenu = (itemId) => {
    setActiveMenuItemId(itemId);
  };

  const [allCollapsed, setAllCollapsed] = useState(false);
  const [collapsedDates, setCollapsedDates] = useState({});

  const toggleDate = (date) => {
    setCollapsedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  const isCollapsed = (date) => collapsedDates[date];

  const handleCollapseAll = () => {
    setAllCollapsed(!allCollapsed);

    const newCollapsedState = {};

    Object.keys(repeatingTransactions).forEach((date) => {
      newCollapsedState[date] = !allCollapsed;
    });

    setCollapsedDates(newCollapsedState);
  };

  // Group transactions by repeat interval
  const groupTransactionsByRepeatInterval = (transactions) => {
    const repeatIntervalHeaders = {
      2: (date) => `Every day`,
      3: (date) => `Every ${format(new Date(date), "EEEE")}`,
      4: (date) => `Every month on day ${new Date(date).getDate()}`,
      5: (date) => `Every year on ${format(new Date(date), "MMMM dd")}`,
    };

    const groupedByRepeatInterval = transactions.reduce(
      (groups, transaction) => {
        const { repeatIntervalId, transactionDate } = transaction;
        const header = repeatIntervalHeaders[repeatIntervalId](transactionDate);

        if (!groups[header]) {
          groups[header] = { repeatIntervalId, transactions: [] };
        }

        groups[header].transactions.push(transaction);
        return groups;
      },
      {}
    );

    // Sort headers by repeatIntervalId (reverse order)
    const sortedHeaders = Object.keys(groupedByRepeatInterval).sort(
      (headerA, headerB) =>
        groupedByRepeatInterval[headerA].repeatIntervalId -
        groupedByRepeatInterval[headerB].repeatIntervalId
    );

    // Build sorted groups with transactions sorted by ascending transactionDate
    const sortedGroups = sortedHeaders.reduce((sorted, header) => {
      sorted[header] = groupedByRepeatInterval[header].transactions.sort(
        (a, b) => new Date(a.transactionDate) - new Date(b.transactionDate)
      );
      return sorted;
    }, {});

    return sortedGroups;
  };

  const repeatingTransactions =
    groupTransactionsByRepeatInterval(displayTransactions);

  return (
    <div className="p-5 select-none pb-28">
      {Object.keys(repeatingTransactions).length > 0 ? (
        <>
          <div className="text-end text-sm text-gray-600 dark:text-gray-400">
            <button className="cursor-pointer" onClick={handleCollapseAll}>
              {allCollapsed ? "Expand all" : "Collapse all"}
            </button>
          </div>

          {/* Display Grouped Transactions */}
          {Object.keys(repeatingTransactions).map((header) => (
            <div key={header} className="mb-6">
              <h3
                className="text-xl font-semibold mb-4 flex items-center justify-between text-black dark:text-gray-300 cursor-pointer"
                onClick={() => toggleDate(header)}
              >
                {header}
              </h3>

              {!isCollapsed(header) &&
                repeatingTransactions[header].map((transaction) => (
                  <TransactionsItem
                    item={transaction}
                    key={transaction.id}
                    onClick={() => openModal(transaction)}
                    onDelete={handleDelete}
                    isActiveMenu={activeMenuItemId === transaction.id}
                    openMenu={() => openMenu(transaction.id)}
                    closeMenu={closeMenu}
                    setIsCopy={setIsCopy}
                  />
                ))}
            </div>
          ))}
        </>
      ) : (
        <div className="text-center text-xl font-medium mt-10 text-black dark:text-gray-300">
          {loading
            ? "Loading..."
            : "No repeating transactions found, add some!"}
        </div>
      )}
    </div>
  );
};

export default TransactionsRepeatingList;

import { useAuth } from "../../components/Auth/AuthProvider";
import { makeApiCall } from "../ApiService/ApiCall";

const useStocksData = () => {
  const { userId } = useAuth();

  // GET

  const getStocks = async () => {
    const requestUrl = `/Stocks/GetStocks/${userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    return result.data;
  };

  const getStockTransactions = async (stocks) => {
    if (!stocks) {
      stocks = await getStocks();
    }

    const requestUrl = `/Stocks/GetStockTransactions/${userId}`;
    const result = await makeApiCall("GET", requestUrl, null);

    return result.data;
  };

  // POST

  const createStock = async (item) => {
    let requestUrl = `/Stocks/CreateStock/${userId}`;
    let apiMethod = "POST";

    const result = await makeApiCall(apiMethod, requestUrl, {
      Ticker: item.ticker,
      Name: item.name,
      Description: item.description,
      Currency: item.currency,
      Price: item.price,
      GlobalUpdate: item.globalUpdate,
    });

    return result;
  };

  const createStockTransaction = async (item) => {
    let requestUrl = `/Stocks/CreateStockTransaction/${userId}`;
    let apiMethod = "POST";

    const result = await makeApiCall(apiMethod, requestUrl, {
      StockId: item.stockId,
      Amount: item.amount,
      Currency: item.currency,
      Price: item.price,
      Description: item.description,
      TransactionDate: item.transactionDate,
    });

    return result;
  };

  // PUT

  const updateStock = async (item) => {
    let requestUrl = `/Stocks/UpdateStock/${userId}/${item.id}`;
    let apiMethod = "PUT";

    console.log(item);

    const result = await makeApiCall(apiMethod, requestUrl, {
      Ticker: item.ticker,
      Name: item.name,
      Description: item.description,
      Currency: item.currency,
      Price: item.price,
      GlobalUpdate: item.globalUpdate,
    });

    return result;
  };

  const updateStockTransaction = async (item) => {
    let requestUrl = `/Stocks/UpdateStockTransaction/${userId}/${item.id}`;
    let apiMethod = "PUT";

    console.log(item);

    const result = await makeApiCall(apiMethod, requestUrl, {
      StockId: item.stockId,
      Amount: item.amount,
      Currency: item.currency,
      Price: item.price,
      Description: item.description,
      TransactionDate: item.transactionDate,
    });

    return result;
  };

  // PATCH

  const updateStocksPrices = async () => {
    let requestUrl = `/Stocks/UpdateStocksPrices/${userId}`;
    let apiMethod = "PATCH";

    const result = await makeApiCall(apiMethod, requestUrl, null);

    return result;
  };

  // DELETE

  const deleteStock = async (item) => {
    const requestUrl = `/Stocks/DeleteStock/${userId}/${item.id}`;
    let apiMethod = "DELETE";

    const result = await makeApiCall(apiMethod, requestUrl, null);

    return result;
  };

  const deleteStockTransaction = async (item) => {
    const requestUrl = `/Stocks/DeleteStockTransaction/${userId}/${item.id}`;
    let apiMethod = "DELETE";

    const result = await makeApiCall(apiMethod, requestUrl, null);

    return result;
  };

  return {
    getStocks,
    getStockTransactions,
    createStock,
    createStockTransaction,
    updateStock,
    updateStockTransaction,
    updateStocksPrices,
    deleteStock,
    deleteStockTransaction,
  };
};

export default useStocksData;

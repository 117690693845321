import React from "react";
import useTransactionsStore from "../../../store/TransactionsStore";
import useTransactionsLogic from "../../../hooks/TransactionsLogic";
import TransactionsRepeatingList from "./TransactionsRepeatingList";

const TransactionsRepeating = ({ openModalRepeating, setIsCopy }) => {
  const { repeatingTransactions } = useTransactionsStore();
  const { removeRepeatingTransaction } = useTransactionsLogic();

  const handleDeleteRepeatingTransaction = async (transaction) => {
    const result = await removeRepeatingTransaction(transaction);
    if (result) {
      alert("An error occured. Please try again.");
    }
  };

  return (
    <TransactionsRepeatingList
      openModal={openModalRepeating}
      displayTransactions={repeatingTransactions}
      handleDelete={handleDeleteRepeatingTransaction}
      setIsCopy={setIsCopy}
    />
  );
};

export default TransactionsRepeating;

import React, { useEffect, useState } from "react";

const Notification = ({ count, remainingTime, message, onClose }) => {
  const [timeLeft, setTimeLeft] = useState(remainingTime);

  useEffect(() => {
    setTimeLeft(remainingTime);
  }, [remainingTime]);

  return (
    <div
      className="fixed top-4 right-4 bg-red-600 text-white px-4 py-3 rounded-lg shadow-lg w-[90%] md:w-[23%] animate-slide-in"
      style={{ zIndex: 9999 }}
    >
      <div className="flex justify-between items-center">
        <span className="text-sm">
          {count > 1 && `(${count}) `}
          {message}
        </span>
        <div className="ml-3">
          <span>{Math.ceil(timeLeft / 1000)}s</span>
          <button
            onClick={onClose}
            className="text-white font-bold text-lg leading-none ml-1 hover:text-gray-200"
          >
            &times;
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notification;

import React, { useState, useEffect } from "react";
import useStocksStore from "../../../store/StocksStore";
import StockTransactionsGroup from "./StockTransactionsGroup";

const StockTransactions = ({ openModal }) => {
  const { loading, stocks, stockTransactions } = useStocksStore();

  const [groupedTransactions, setGroupedTransactions] = useState([]);

  useEffect(() => {
    if (stocks && stockTransactions?.length > 0) {
      const grouped = stocks.map((currency) => {
        const transactions = stockTransactions.filter(
          (tx) => tx.stockId === currency.id
        );

        const totalStockAmount = transactions.reduce(
          (total, tx) => total + tx.amount,
          0
        );

        const totalPurchasePrice = transactions.reduce(
          (total, tx) => total + tx.price,
          0
        );

        const totalCurrentValue =
          currency.price *
          transactions.reduce((total, tx) => total + tx.amount, 0);

        return {
          currency,
          transactions,
          totalStockAmount,
          totalPurchasePrice,
          totalCurrentValue,
        };
      });

      const sortedGrouped = grouped.sort(
        (a, b) => b.totalCurrentValue - a.totalCurrentValue
      );

      setGroupedTransactions(sortedGrouped);
    } else {
      setGroupedTransactions([]);
    }
  }, [stocks, stockTransactions]);

  return (
    <div className="grid lg:grid-cols-2 md:grid-cols-1 pb-32">
      {groupedTransactions?.length > 0 ? (
        groupedTransactions.map(
          ({
            currency,
            transactions,
            totalStockAmount,
            totalPurchasePrice,
            totalCurrentValue,
          }) =>
            transactions.length > 0 && (
              <StockTransactionsGroup
                key={currency.id}
                currency={currency}
                transactions={transactions}
                totalStockAmount={totalStockAmount}
                totalCurrentValue={totalCurrentValue}
                totalPurchasePrice={totalPurchasePrice}
                openModal={openModal}
              />
            )
        )
      ) : (
        <div className="text-center text-xl font-medium mt-10 text-black dark:text-gray-300">
          {loading ? "Loading..." : "No transactions found, add some!"}
        </div>
      )}
    </div>
  );
};

export default StockTransactions;

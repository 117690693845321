const AssetsItemIcon = ({ item }) => {
  switch (item) {
    case "Collectibles":
      return "🖼️";
    case "Cryptocurrencies":
      return "💰";
    case "Derivates":
      return "🛢️";
    case "Intellectual Property":
      return "📜";
    case "Precious Metals":
      return "🪙";
    case "Real Estate":
      return "🏠";
    case "Retirement Accounts":
      return "📊";
    case "Stock Market":
      return "📈";
    case "Watches":
      return "⌚";
    case "Jewelry":
      return "💎";
    case "Bonds":
      return "💵";
    case "Commodities":
      return "🌾";
    case "Art":
      return "🎨";
    case "Vehicles":
      return "🚗";
    case "Antiques":
      return "🏺";
    case "Private Equity":
      return "🤝";
    case "Venture Capital":
      return "🚀";
    case "Mutual Funds":
      return "📁";
    case "Fine Wine":
      return "🍷";
    case "Luxury Goods":
      return "👜";
    case "Collectible Cards":
      return "🎴";
    case "Patents":
      return "💡";
    case "Savings Accounts":
      return "🏦";
    default:
      return "💼";
  }
};

export default AssetsItemIcon;

import { useState, useEffect } from "react";
import useAssetsData from "../services/DataService/AssetsData";
import useAssetsStore from "../store/AssetsStore";
import { useNotification } from "../components/Notification/NotificationProvider";

const useAssetsLogic = () => {
  const { addNotification } = useNotification();

  const {
    setLoading,
    setEditing,
    dataChanged,
    setDataChanged,
    setAssets,
    setAssetTypes,
    resetAssetsStore,
  } = useAssetsStore();

  const {
    getAssets,
    getAssetTypes,
    createAsset,
    createAssetType,
    updateAsset,
    updateAssetType,
    deleteAsset,
    deleteAssetType,
  } = useAssetsData();

  const [refreshAssets, setRefreshAssets] = useState(false);

  useEffect(() => {
    if (dataChanged) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChanged, refreshAssets]);

  const loadData = async () => {
    try {
      setLoading(true);

      let assetTypesData = await loadAssetTypes();
      setAssetTypes(assetTypesData);

      let assetsData = await loadAssets();
      setAssets(assetsData);
    } catch (error) {
      const errorMessage = `Failed to fetch assets data: ${error?.message}`;
      console.error(errorMessage);
      if (error?.message !== undefined) {
        addNotification(errorMessage);
      }
    } finally {
      setDataChanged(false);
      setLoading(false);
    }
  };

  // LOAD

  const loadAssetTypes = async () => {
    let assetTypesData = JSON.parse(localStorage.getItem("assetTypes"));

    if (!assetTypesData) {
      assetTypesData = await getAssetTypes();

      if (assetTypesData) {
        assetTypesData.sort((a, b) => a.name.localeCompare(b.name));

        localStorage.setItem("assetTypes", JSON.stringify(assetTypesData));
      }
    }

    return assetTypesData;
  };

  const loadAssets = async (assetTypesData) => {
    let assetsData = JSON.parse(localStorage.getItem("assets"));

    if (assetsData) {
      assetsData.sort(
        (a, b) => new Date(b.purchaseDate) - new Date(a.purchaseDate)
      );
    } else {
      assetsData = await getAssets(assetTypesData);
      if (assetsData) {
        localStorage.setItem("assets", JSON.stringify(assetsData));
      }
    }

    return assetsData;
  };

  // ADD

  const addAsset = async (item) => {
    setEditing(true);

    try {
      const result = await createAsset(item);

      localStorage.removeItem("assets");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      if (error?.message) {
        console.error(error.message);
        addNotification(error.message);
      }
    }

    setEditing(false);
  };

  const addAssetType = async (item) => {
    setEditing(true);

    try {
      const result = await createAssetType(item);

      localStorage.removeItem("assetTypes");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      if (error?.message) {
        console.error(error.message);
        addNotification(error.message);
      }
    }

    setEditing(false);
  };

  // EDIT

  const editAsset = async (item) => {
    setEditing(true);

    try {
      const result = await updateAsset(item);

      localStorage.removeItem("assets");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      if (error?.message) {
        console.error(error.message);
        addNotification(error.message);
      }
    }

    setEditing(false);
  };

  const editAssetType = async (item) => {
    setEditing(true);

    try {
      const result = await updateAssetType(item);

      localStorage.removeItem("assetTypes");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      if (error?.message) {
        console.error(error.message);
        addNotification(error.message);
      }
    }

    setEditing(false);
  };

  // REMOVE

  const removeAsset = async (item) => {
    setEditing(true);

    try {
      const result = await deleteAsset(item);

      localStorage.removeItem("assets");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      if (error?.message) {
        console.error(error.message);
        addNotification(error.message);
      }
    }

    setEditing(false);
  };

  const removeAssetType = async (item) => {
    setEditing(true);

    try {
      const result = await deleteAssetType(item);

      localStorage.removeItem("assetTypes");
      setDataChanged(true);

      if (result?.status !== 200) {
        return result;
      }
    } catch (error) {
      if (error?.message) {
        console.error(error.message);
        addNotification(error.message);
      }
    }

    setEditing(false);
  };

  // CLEAR

  const clearAssetsCache = () => {
    resetAssetsStore();

    setDataChanged(true);
  };

  return {
    addAsset,
    addAssetType,
    editAsset,
    editAssetType,
    removeAsset,
    removeAssetType,
    clearAssetsCache,
    setRefreshAssets,
  };
};

export default useAssetsLogic;

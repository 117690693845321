import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { formatMoney } from "../../../services/FormatService/FormatText";
import useStocksStore from "../../../store/StocksStore";

const StockTransactionsAlt = ({ openModal }) => {
  const { loading, stocks, stockTransactions } = useStocksStore();

  const [transactionsWithDetails, setTransactionsWithDetails] = useState([]);

  useEffect(() => {
    if (stocks && stockTransactions?.length > 0) {
      const transactionsDetailed = stockTransactions.map((transaction) => {
        const currency = stocks.find(
          (currency) => currency.id === transaction.stockId
        );

        return {
          ...transaction,
          currencyName: currency?.name || "Unknown",
          currencyTicker: currency?.ticker || "Unknown",
          currencyPrice: currency?.price || 0,
          currencyUpdateDate: currency?.updateDate || null,
        };
      });

      const sortedTransactions = transactionsDetailed.sort(
        (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
      );

      setTransactionsWithDetails(sortedTransactions);
    } else {
      setTransactionsWithDetails([]);
    }
  }, [stocks, stockTransactions]);

  return (
    <div className="pb-32">
      <div className="mt-8 mx-5 p-4 rounded-lg shadow-md bg-white dark:bg-gray-800 text-center">
        {transactionsWithDetails?.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full border-collapse table-auto">
              <thead className="bg-gray-100 dark:bg-gray-700">
                <tr>
                  <th className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300">
                    Date
                  </th>
                  <th className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300">
                    Currency
                  </th>
                  <th className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300">
                    Description
                  </th>
                  <th className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300">
                    Quantity
                  </th>
                  <th className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300">
                    Price per Unit
                  </th>
                  <th className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300">
                    Total Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactionsWithDetails.map((transaction) => (
                  <tr
                    key={transaction.id}
                    className="hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
                    onClick={() => openModal(transaction)}
                  >
                    <td className="px-4 py-2 text-sm text-gray-600 dark:text-gray-300">
                      {format(
                        new Date(transaction.transactionDate),
                        "dd.MM.yyyy."
                      )}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-600 dark:text-gray-300">
                      {transaction.currencyName} ({transaction.currencyTicker})
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-600 dark:text-gray-300">
                      {transaction.description}
                    </td>
                    <td
                      className={`px-4 py-2 text-sm font-medium ${
                        transaction.amount <= 0
                          ? "text-red-500"
                          : "text-green-500"
                      }`}
                    >
                      {transaction.amount.toFixed(8)}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-600 dark:text-gray-300">
                      {formatMoney(transaction.price / transaction.amount, 2)}{" "}
                      {transaction.currency}
                    </td>
                    <td
                      className={`px-4 py-2 text-sm font-medium ${
                        transaction.price <= 0
                          ? "text-red-500"
                          : "text-green-500"
                      }`}
                    >
                      {formatMoney(transaction.price, 2)} {transaction.currency}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center text-xl font-medium mt-10 text-black dark:text-gray-300">
            {loading ? "Loading..." : "No transactions found, add some!"}
          </div>
        )}
      </div>
    </div>
  );
};

export default StockTransactionsAlt;

import React from "react";
import * as XLSX from "xlsx";
import useTransactionsLogic from "../../../hooks/TransactionsLogic";
import useTransactionsStore from "../../../store/TransactionsStore";

const TransactionsSettingsExport = ({ currentUser, currency }) => {
  const { transactions } = useTransactionsStore();

  const { exportTransactionsViaEmail } = useTransactionsLogic();

  // Export to JSON
  const handleExportToJson = () => {
    const filteredData = transactions.map((transaction) => ({
      amount: transaction.amount,
      currency: currency,
      description: transaction.description,
      transactionCategory: transaction.transactionCategory,
      transactionDate: transaction.transactionDate,
    }));

    const data = JSON.stringify(filteredData, null, 2);
    const blob = new Blob([data], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `LT_transactions_export_${new Date().toISOString()}.json`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  // Export to CSV
  const handleExportToCsv = () => {
    const headers = [
      "Amount",
      "Description",
      "TransactionCategoryId",
      "TransactionCategory",
      "TransactionDate",
    ];

    const csv = [
      headers.join(";"),
      ...transactions.map((transaction) =>
        [
          transaction.amount,
          currency,
          transaction.description,
          transaction.transactionCategory,
          transaction.transactionDate,
        ].join(";")
      ),
    ].join("\n");

    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `LT_transactions_export_${new Date().toISOString()}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  // Export to Excel
  function handleExportToExcel() {
    const headers = [
      "Amount",
      "Currency",
      "Description",
      "TransactionCategory",
      "TransactionCategoryId",
      "TransactionDate",
    ];

    const data = transactions.map((transaction) => ({
      Amount: transaction.amount,
      Currency: currency,
      Description: transaction.description,
      TransactionCategory: transaction.transactionCategory,
      TransactionCategoryId: transaction.transactionCategoryId,
      TransactionDate: new Date(transaction.transactionDate),
    }));

    // Create a worksheet
    const ws = XLSX.utils.json_to_sheet(data, { header: headers });

    // Create a workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions");

    // Write the workbook to a binary string
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(wbout)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `LT_transactions_export_${new Date().toISOString()}.xlsx`;

    // Append to the body, click, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Function to convert string to array buffer
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
  }

  // Generate SQL Insert script
  function generateSqlInsertQuery() {
    const tableName = "[Transaction]";

    const columns = [
      "transactionDate",
      "userId",
      "repeatIntervalId",
      "transactionCategoryId",
      "amount",
      "creationDate",
      "description",
    ];

    const userId = "INT_USER_ID";

    const maxValuesPerInsert = 999;

    const values = transactions.map((transaction) => {
      const { transactionDate, transactionCategoryId, amount, description } =
        transaction;
      const sanitizedDescription = description.replace(/'/g, "''");
      return `('${transactionDate}', ${userId}, 1, ${transactionCategoryId}, ${amount}, GETDATE(), N'${sanitizedDescription}')`;
    });

    const chunkArray = (array, size) => {
      const result = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    };

    const sqlStatements = chunkArray(values, maxValuesPerInsert).map(
      (chunk) => {
        return (
          `INSERT INTO ${tableName} (${columns.join(", ")}) VALUES\n` +
          chunk.join(",\n") +
          ";"
        );
      }
    );

    const disclaimer = `/* Replace ${userId} with desired UserID (int) */\n\n`;

    const sqlQuery = disclaimer + sqlStatements.join("\n\n");

    const blob = new Blob([sqlQuery], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.download = `LT_transactions_SQL-insert_${new Date().toISOString()}.txt`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  }

  // Email Export
  const handleEmailExport = () => {
    if (currentUser.verified) {
      const exportData = {
        name: currentUser.username,
        email: currentUser.email,
      };
      exportTransactionsViaEmail(exportData);
      alert("Email sent successfully!");
    } else {
      alert(
        "Only verified users can export via email. Please verify your email address and try again."
      );
    }
  };

  return (
    <div className="space-y-4 mt-4">
      <button
        onClick={handleExportToJson}
        className="bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600"
      >
        Export to JSON
      </button>

      <button
        onClick={handleExportToCsv}
        className="bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600"
      >
        Export to CSV
      </button>

      <button
        onClick={handleExportToExcel}
        className="bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600"
      >
        Export to Excel
      </button>

      {process.env.NODE_ENV === "development" && (
        <button
          onClick={generateSqlInsertQuery}
          className="bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600"
        >
          [DEV] Generate SQL Insert script
        </button>
      )}

      <button
        onClick={handleEmailExport}
        className="bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600 disabled:bg-gray-400 disabled:text-gray-700"
        disabled={!currentUser.verified}
      >
        Export via Email (JSON & CSV)
      </button>
    </div>
  );
};

export default TransactionsSettingsExport;

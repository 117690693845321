import { create } from "zustand";

const useStocksStore = create((set) => ({
  loading: false,
  editing: false,
  dataChanged: true,
  stocks: [],
  stockTransactions: [],

  setLoading: (loading) => set({ loading }),

  setEditing: (editing) => set({ editing }),

  setDataChanged: (dataChanged) => set({ dataChanged }),

  setStocks: (newStocks) => set({ stocks: newStocks }),

  setStockTransactions: (newStockTransactions) =>
    set({ stockTransactions: newStockTransactions }),

  resetStocksStore: () => {
    localStorage.removeItem("stocks");
    localStorage.removeItem("stockTransactions");

    set(() => ({
      loading: false,
      editing: false,
      dataChanged: true,
      stocks: [],
      stockTransactions: [],
    }));
  },
}));

export default useStocksStore;

import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Body from "../../components/Body/Body";
import AddButton from "../../components/Body/AddButton";
import useCryptoLogic from "../../hooks/CryptoLogic";
import useCryptoStore from "../../store/CryptoStore";
import CryptoModal from "../../components/Cryptos/CryptoModal";
import CryptoTransactions from "../../components/Cryptos/CryptoTransactions/CryptoTransactions";
import CryptoTransactionsAlt from "../../components/Cryptos/CryptoTransactionsAlt/CryptoTransactionsAlt";
import CryptoSettings from "../../components/Cryptos/CryptoSettings/CryptoSettings";
import { formatMoney } from "../../services/FormatService/FormatText";
import { FaCog, FaSync } from "react-icons/fa";
import { FaList, FaObjectGroup } from "react-icons/fa6";

const Cryptos = () => {
  const { loading, editing, cryptoCurrencies, cryptoTransactions } =
    useCryptoStore();

  const {
    addCryptoTransaction,
    editCryptoTransaction,
    removeCryptoTransaction,
    clearCryptoCurrenciesCache,
  } = useCryptoLogic();

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingTransaction, setEditingTransaction] = useState(null);

  const [selectedView, setSelectedView] = useState("groups");

  const handleOpenSettings = () => setIsSettingsOpen(true);
  const handleCloseSettings = () => setIsSettingsOpen(false);

  const openModal = (transaction = null) => {
    setEditingTransaction(transaction);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditingTransaction(null);
  };

  const changeSelectedView = () => {
    selectedView === "groups"
      ? setSelectedView("list")
      : setSelectedView("groups");
  };

  const handleSave = async (transaction) => {
    if (editingTransaction) {
      await editCryptoTransaction(transaction);
    } else {
      await addCryptoTransaction(transaction);
    }
    closeModal();
  };

  const totalPurchasePrice = cryptoTransactions.reduce(
    (total, transaction) => total + transaction.fiatAmount,
    0
  );

  const totalCurrentWorth = cryptoTransactions.reduce((total, transaction) => {
    const currency = cryptoCurrencies.find(
      (currency) => currency.id === transaction.cryptoCurrencyId
    );

    return total + transaction.cryptoAmount * (currency ? currency.price : 0);
  }, 0);

  const percentageDifference =
    ((totalCurrentWorth - totalPurchasePrice) / totalPurchasePrice) * 100;

  useEffect(() => {
    if (cryptoTransactions.length > 0 && totalCurrentWorth <= 0 && !loading) {
      setSelectedView("List");
    }
  }, [cryptoTransactions, totalCurrentWorth, loading]);

  return (
    <>
      <Header />
      <Body>
        {/* Settings Button */}
        <div className="absolute top-20 left-4">
          <button
            onClick={handleOpenSettings}
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
          >
            <FaCog size={15} />
          </button>
        </div>

        {/* Refresh Button */}
        <div className="absolute top-20 right-4">
          <button
            onClick={clearCryptoCurrenciesCache}
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600 disabled:cursor-not-allowed"
            disabled={editing || loading}
          >
            <FaSync size={15} className={`${loading && "animate-spin"}`} />
          </button>
        </div>

        {/* Switch view button */}
        <div className="absolute top-28 mt-2 right-4">
          <button
            onClick={changeSelectedView}
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600 disabled:cursor-not-allowed"
            disabled={totalCurrentWorth <= 0}
          >
            {selectedView === "groups" ? (
              <FaList size={15} />
            ) : (
              <FaObjectGroup size={15} />
            )}
          </button>
        </div>

        <h2 className="text-xl text-center mt-4 text-black dark:text-gray-300">
          {"Total Invested: "}
          <span className="font-bold">
            {formatMoney(totalPurchasePrice, 2)} €
          </span>
        </h2>

        <h2 className="text-xl text-center mt-4 text-black dark:text-gray-300">
          {"Current Net Worth: "}
          <span className="font-bold">
            {formatMoney(totalCurrentWorth, 2)} €
          </span>
        </h2>

        {!isNaN(percentageDifference) && (
          <h2 className="text-xl text-center mt-4 text-black dark:text-gray-300">
            {"Investment Growth: "}
            <span
              className={`font-bold ${
                percentageDifference < 0 ? "text-red-500" : "text-green-500"
              }`}
            >
              {percentageDifference > 0 && "+"}
              {formatMoney(totalCurrentWorth - totalPurchasePrice, 2)} € (
              {percentageDifference > 0 && "+"}
              {formatMoney(percentageDifference, 2)}%)
            </span>
          </h2>
        )}

        {selectedView === "groups" ? (
          <CryptoTransactions openModal={openModal} />
        ) : (
          <CryptoTransactionsAlt openModal={openModal} />
        )}
      </Body>

      {/* Add Crypto Transaction Button */}
      <AddButton openModal={openModal} isLoading={editing || loading} />

      {isModalOpen && (
        <CryptoModal
          initialData={editingTransaction}
          onSave={handleSave}
          onDelete={removeCryptoTransaction}
          onClose={closeModal}
        />
      )}

      {isSettingsOpen && <CryptoSettings onClose={handleCloseSettings} />}
    </>
  );
};

export default Cryptos;

import { useEffect } from "react";
import useCurrentUserData from "../services/DataService/CurrentUserData";
import useCurrentUserStore from "../store/CurrentUserStore";
import { useNotification } from "../components/Notification/NotificationProvider";

const useCurrentUserLogic = () => {
  const { addNotification } = useNotification();

  const {
    setLoading,
    dataChanged,
    setDataChanged,
    setCurrentUser,
    setCurrentUserInfo,
    setCurrentUserAddress,
  } = useCurrentUserStore();

  const {
    getUser,
    getUserInfo,
    getUserAddress,
    getUserLastLogIn,
    updateUserPassword,
    logoutUser,
    updateUser,
    updateUserInfo,
    updateUserLastLogIn,
  } = useCurrentUserData();

  useEffect(() => {
    if (dataChanged) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChanged]);

  const loadData = async () => {
    try {
      setLoading(true);

      let currentUserData = await loadCurrentUser();
      setCurrentUser(currentUserData);

      let currentUserInfoData = await loadCurrentUserInfo();
      setCurrentUserInfo(currentUserInfoData);

      let currentUserAddressData = await loadCurrentUserAddress();
      setCurrentUserAddress(currentUserAddressData);
    } catch (error) {
      const errorMessage = `Failed to fetch current user data: ${error?.message}`;
      console.error(errorMessage);
      if (error?.message !== undefined) {
        addNotification(errorMessage);
      }
    } finally {
      setDataChanged(false);
      setLoading(false);
    }
  };

  // LOAD

  const loadCurrentUser = async () => {
    let currentUserData = JSON.parse(localStorage.getItem("currentUser"));

    if (!currentUserData) {
      currentUserData = await getUser();
      if (currentUserData) {
        localStorage.setItem("currentUser", JSON.stringify(currentUserData));
      }
    }

    return currentUserData;
  };

  const loadCurrentUserInfo = async () => {
    let currentUserInfoData = JSON.parse(
      localStorage.getItem("currentUserInfo")
    );

    if (!currentUserInfoData) {
      currentUserInfoData = await getUserInfo();
      if (currentUserInfoData) {
        localStorage.setItem(
          "currentUserInfo",
          JSON.stringify(currentUserInfoData)
        );
      }
    }

    return currentUserInfoData;
  };

  const loadCurrentUserAddress = async () => {
    let currentUserAddressData = JSON.parse(
      localStorage.getItem("currentUserAddress")
    );

    if (!currentUserAddressData) {
      currentUserAddressData = await getUserAddress();
      if (currentUserAddressData) {
        localStorage.setItem(
          "currentUserAddress",
          JSON.stringify(currentUserAddressData)
        );
      }
    }

    return currentUserAddressData;
  };

  // FETCH

  const fetchUserLastLogIn = async () => {
    let currentUserLastLogInData = JSON.parse(
      localStorage.getItem("currentUserLastLogIn")
    );

    if (!currentUserLastLogInData) {
      currentUserLastLogInData = await getUserLastLogIn();
      if (currentUserLastLogInData) {
        localStorage.setItem(
          "currentUserAddress",
          JSON.stringify(currentUserLastLogInData)
        );
      }
    }

    return currentUserLastLogInData;
  };

  // UPDATE

  const editUser = async (item) => {
    const result = await updateUser(item);

    localStorage.removeItem("currentUser");
    setDataChanged(true);

    if (result?.status !== 200) {
      alert("Couldn't update user, please try again.");
      return result;
    } else {
      alert("User updated successfully.");
    }
  };

  const editUserInfo = async (item) => {
    const result = await updateUserInfo(item);

    localStorage.removeItem("currentUserInfo");
    setDataChanged(true);

    if (result?.status !== 200) {
      alert("Couldn't update user info, please try again.");
      return result;
    } else {
      alert("User Info updated successfully.");
    }
  };

  const changePassword = async (item) => {
    try {
      const result = await updateUserPassword(item);

      if (result?.status === 200) {
        alert("Password changed successfully.");
      } else if (result?.response.status === 401) {
        alert("Incorrect current password!");
      } else {
        alert("Couldn't change password, please try again.");
      }

      return result;
    } catch (error) {
      alert("Error changing password, please try again.");
      console.log(error);
      return error;
    }
  };

  const changeUserLastLogIn = async () => {
    const result = await updateUserLastLogIn();

    return result;
  };

  // LOGOUT

  const logoutCurrentUser = async () => {
    const result = await logoutUser();

    window.location.reload();

    return result.data;
  };

  return {
    fetchUserLastLogIn,
    editUser,
    editUserInfo,
    changePassword,
    changeUserLastLogIn,
    logoutCurrentUser,
  };
};

export default useCurrentUserLogic;

import React, { createContext, useContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [cookies] = useCookies();

  const [userId, setUserId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const userIdExists =
      cookies.userId !== null && cookies.userId !== undefined;

    if (userIdExists !== undefined) {
      setUserId(cookies.userId);
      setIsLoggedIn(userIdExists);
    }
  }, [cookies.userId]);

  const logOut = () => {
    setIsLoggedIn(false);
    setUserId(null);

    localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ userId, isLoggedIn, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

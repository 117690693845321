import { create } from "zustand";

const useCryptoStore = create((set) => ({
  loading: false,
  editing: false,
  dataChanged: true,
  cryptoCurrencies: [],
  cryptoTransactions: [],

  setLoading: (loading) => set({ loading }),

  setEditing: (editing) => set({ editing }),

  setDataChanged: (dataChanged) => set({ dataChanged }),

  setCryptoCurrencies: (newCryptoCurrencies) =>
    set({ cryptoCurrencies: newCryptoCurrencies }),

  setCryptoTransactions: (newCryptoTransactions) =>
    set({ cryptoTransactions: newCryptoTransactions }),

  resetCryptoStore: () => {
    localStorage.removeItem("cryptoCurrencies");
    localStorage.removeItem("cryptoTransactions");

    set(() => ({
      loading: false,
      editing: false,
      dataChanged: true,
      cryptoCurrencies: [],
      cryptoTransactions: [],
    }));
  },
}));

export default useCryptoStore;

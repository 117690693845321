import React, { createContext, useState, useContext } from "react";
import Notification from "./Notification";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message) => {
    const id = Date.now();
    const duration = 10000; // 10 seconds for notification to disappear

    const newNotification = {
      id,
      message,
      duration,
      remainingTime: duration,
      isExiting: false,
    };

    setNotifications((prev) => [...prev, newNotification]);

    // Start the countdown for this notification
    const countdownInterval = setInterval(() => {
      setNotifications((prev) =>
        prev.map((n) =>
          n.id === id
            ? {
                ...n,
                remainingTime: Math.max(0, n.remainingTime - 1000), // Decrease by 1 second
              }
            : n
        )
      );
    }, 1000);

    // Auto-remove notification after duration is over
    setTimeout(() => {
      clearInterval(countdownInterval);
      removeNotificationWithAnimation(id);
    }, duration);
  };

  const removeNotificationWithAnimation = (id) => {
    setNotifications((prev) =>
      prev.map((n) => (n.id === id ? { ...n, isExiting: true } : n))
    );

    // Wait for the exit animation to finish
    setTimeout(() => removeNotification(id), 400); // Match the animation duration
  };

  const removeNotification = (id) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id));
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      <div className="fixed top-4 right-4 flex flex-col items-end space-y-2 z-50">
        {notifications.map((n) => (
          <div
            key={n.id}
            className={`transition-transform duration-300 ${
              n.isExiting ? "transform translate-x-full opacity-0" : ""
            }`}
          >
            <Notification
              count={notifications.length}
              message={n.message}
              remainingTime={n.remainingTime}
              onClose={() => removeNotification(n.id)}
            />
          </div>
        ))}
      </div>
    </NotificationContext.Provider>
  );
};

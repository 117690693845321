import React from "react";
import ReactDOM from "react-dom/client";
import { NotificationProvider } from "./components/Notification/NotificationProvider";
import { AuthProvider } from "./components/Auth/AuthProvider";
import App from "./App";
import "./index.css";

const isDevelopment = process.env.NODE_ENV === "development";

const root = ReactDOM.createRoot(document.getElementById("root"));

const AppWrapper = () =>
  isDevelopment ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  );

root.render(
  <NotificationProvider>
    <AuthProvider>
      <AppWrapper />
    </AuthProvider>
  </NotificationProvider>
);
